import gql from "graphql-tag";


// export const LAST_VIEWED = gql`

// `;

export const GET_UNLABELLED_IDS = gql`
query MyQuery($startId: Int!, $endId: Int!) {
  atr_correction_aggregate(distinct_on: id, where: {isLabelled: {_eq: "Not Labelled"}, id: {_gte: $startId, _lte: $endId}}) {
    aggregate {
      count
    }
    nodes {
      id
    }
  }
}
`;

// export const UPDATE_DELETE = gql`
// mutation MyMutation(
//   $id: Int!,
//   $name: String,
//   $seg_var: String!
//   $last_updated: timestamptz,
// ) {
//   update_atr_correction(
//     where: { id: { _eq: $id } }
//     _set: { 
//         # rest_seg:$rest_seg,
//         isLabelled: "Labelled" 
//         name: $name
//         $seg_var: null
//         # upper_clothes_seg :$upper_clothes_seg,
//         # lower_clothes_seg :$lower_clothes_seg,
//         # dress_seg :$dress_seg,
//         # belt_seg :$belt_seg,
//         # heavy_tops_seg :$heavy_tops_seg,
//         # skirt_seg :$skirt_seg,
//         # coat_seg: $coat_seg,
//         is_perfect: "No"
//         last_updated : $last_updated
//         # is_na: "No",
//         # reason_for_na: "No"
//       }
//   ) {
//     affected_rows
//   }
// }
// `;
export const UPDATE_PERSON_NA = gql`
  mutation MyMutation(
    $id: Int!,
    $is_na: String,
    $reason_for_na: String!,
    $isLabelled: String!,
    $last_updated: timestamptz,
    # $seconds_spent: Int,
  ){
      update_atr_correction(
        where: { id: { _eq: $id } }
        _set: {
          isLabelled: $isLabelled,
          is_na: $is_na,
          reason_for_na: $reason_for_na,
          last_updated: $last_updated
          # seconds_spent: $seconds_spent
        }
      ) {
        affected_rows
        returning {
          is_na
        }
      }
    }
`;

export const SECONDS_VIEWED = gql`
mutation SecondsViewed(
  $id: Int!,
  $seconds_viewed: Int!,
  $last_viewed: timestamptz!
  $seconds_spent: Int,

) {
  update_atr_correction(
    where: {id: {_eq: $id}}
    _set: {
      seconds_viewed: $seconds_viewed
      last_viewed: $last_viewed
      seconds_spent: $seconds_spent
    }
  ){
    affected_rows
  }
}
`;
// $seconds_spent_before_save: Int,
// $last_viewed: Int,
// $last_updated: Int,
// $seconds_viewed: Int
export const PASSWORD_QUERY = gql`
query MyQuery($password: String!) {
  password_for_tools_aggregate(where: {tool_name: {_eq: "GRAPY_7"}, password: {_eq: $password}}) {
    aggregate {
      count
    }
    nodes {
      locked_ids
    }
  }
}
`;
export const WORST_CASE_QUERY = gql`
query  MyQuery($id: Int!, $worst_case_date: String) {
  atr_correction(order_by: {id: asc}, where: {worst_case_date: {_eq: $worst_case_date}, id: {_gte: $id}}, limit: 2) {
    id
  }
}
`;

export const UPDATE_PERSON_ASSIGNED = gql`
  mutation MyMutation(
    $id: Int!,
    $upper_clothes_assigned: String!,
    $lower_clothes_assigned: String!
    $dress_assigned: String!,
    $belt_assigned: String!,
    $coat_assigned: String!,
    $skirt_assigned: String!,
    # $bag_present: String!,
    $name: String!,
    # $is_na: String!,
    $isLabelled: String!
    
  ) {
    update_atr_correction(
      where: { id: { _eq: $id } }
      _set: { 
        # bag_present: $bag_present,
        upper_clothes_assigned: $upper_clothes_assigned, 
        lower_clothes_assigned: $lower_clothes_assigned, 
        dress_assigned: $dress_assigned, belt_assigned: 
        $belt_assigned, coat_assigned: $coat_assigned, 
        skirt_assigned: $skirt_assigned, 
        # isLabelled: "Labelled",
        name: $name,
        is_na: "No",
        reason_for_na: "No",
        isLabelled: $isLabelled
      }
    ) {
      affected_rows
    }
  }
`;

// seconds_spent_before_save: $seconds_spent_before_save,
// last_viewed: $last_viewed,
// last_updated: $last_updated,
// seconds_viewed: $seconds_viewed 
export const GET_IMAGE_URL = gql`
  query MyQuery($id: Int!) {
    atr_correction(where: {id: {_eq: $id}}) {
        isLabelled
        id
        upper_clothes_seg
        lower_clothes_seg
        dress_seg
        rest_seg
        belt_seg
        person_id
        coat_seg
        skirt_seg
        upper_clothes_assigned
        lower_clothes_assigned
        dress_assigned
        belt_assigned
        coat_assigned 
        skirt_assigned
        heavy_tops_seg
        is_perfect
        bag_present
        is_na
        reason_for_na
        bag_seg
        other_accessory_seg
        tie_seg
        seconds_spent
    }
  }
`;

export const UPDATE_PERSON = gql`
  mutation MyMutation(
    $id: Int!,
    $rest_seg: String,
    $name: String,
    $upper_clothes_seg: String,
    $lower_clothes_seg: String,
    $dress_seg: String,
    $belt_seg: String,
    $heavy_tops_seg: String,
    $skirt_seg: String,
    $coat_seg: String,
    $last_updated: timestamptz,
    $other_accessory_seg: String,
    $bag_seg: String
    $tie_seg: String
    $seconds_spent: Int
  ) {
    update_atr_correction(
      where: { id: { _eq: $id } }
      _set: { 
          rest_seg:$rest_seg,
          isLabelled: "Labelled", 
          name: $name,
          upper_clothes_seg :$upper_clothes_seg,
          lower_clothes_seg :$lower_clothes_seg,
          dress_seg :$dress_seg,
          belt_seg :$belt_seg,
          heavy_tops_seg :$heavy_tops_seg,
          skirt_seg :$skirt_seg,
          coat_seg: $coat_seg,
          is_perfect: "No",
          last_updated : $last_updated,
          is_na: "No",
          reason_for_na: "No",
          other_accessory_seg: $other_accessory_seg
          tie_seg: $tie_seg
          bag_seg: $bag_seg
          seconds_spent: $seconds_spent
        }
    ) {
      affected_rows
    }
  }
`;
// seconds_spent_before_save: $seconds_spent_before_save,
// last_viewed: $last_viewed,
// last_updated: $last_updated,
// seconds_viewed: $seconds_viewed 
export const allParts = [
{
  variable: "upper_clothes_seg",
  color: "rgba(32, 173, 10, 255)",
  name: "T shirt / Shirt"
},
{
  variable: "lower_clothes_seg",
  color: "rgba(117, 112, 2, 255)",
  name: "Pants / Shorts"
},
{
  variable: "dress_seg",
  color: "rgba(136, 10, 117, 255)",
  name: "Dress"
},
{
  variable: "other_accessory_seg",
  color: "rgba(222, 121, 121, 255)",
  name: "Other Accessory"
},
{
  variable: "tie_seg",
  color: "rgba(222, 128, 50, 255)",
  name: "Tie"
},
{
  variable: "bag_seg",
  color: "rgba(168, 162, 0, 255)",
  name: "Bag "
},
{
  variable: "belt_seg",
  color: "rgba(255, 255, 0, 255)",
  name: "Belt"
},
{
  variable: "skirt_seg",
  color: "rgba(255, 0, 0, 255)",
  name: "Skirt"
},
{
  variable: "rest_seg",
  color: "rgba(204, 204, 204, 255)",
  name: "Rest"
},
{
  variable: "coat_seg",
  color: "rgba(0, 119, 221, 255)",
  name: "Coat",
  // desc: "Ends below the hips / Longer than jackets / hoodies / sweatshirts",
  classes: {
    color: 'white',
    width: '350px',
    height: '80px',
    whiteSpace: 'inherit',
  },
  extra_info: "Ends below the hips / (Longer than jackets / hoodies / sweatshirts)",
  extra_classes: {
    breakWord: 'none'
  },
  main_class: {
    border: '2px solid black'
  }
},
{
  variable: "heavy_tops_seg",
  color: "rgba(57, 45, 40, 255)",
  name: "Jackets/ Sweatshirts/ Hoodies/ Sweaters (Ends above the hips / shorter than coat)",
  classes: {
    color: 'white',
    width: '350px',
    height: '80px',
    whiteSpace: 'inherit',
  },
  // desc: "Ends above the hips / shorter than coat",
  main_class: {
    border: '2px solid black',
    marginBottom: '5px'
  }
},
]

export const selectOptionsLabels = [
  {
    variable: "upper_clothes_assigned",
    color: "#20AD0A",
    name: "T shirt / Shirt"
  },
  {
    variable: "lower_clothes_assigned",
    color: "#757112",
    name: "Pants"
  },
  {
    variable: "dress_assigned",
    color: "#880A75",
    name: "Dress"
  },
  {
    variable: "belt_assigned",
    color: "#ffff00",
    name: "Belt"
  },
  {
    variable: "coat_assigned",
    color: "#0077DD",
    name: "Coat"
  },
  {
    variable: "skirt_assigned",
    color: "#ff0000",
    name: "Skirt"
  },
  {
    variable: "heavy_tops_assigned",
    color: "#392D28",
    name: "Jackets/ Sweatshirts/ Hoodies"
  },
  {
    variable: "rest_assigned",
    color: "#cccccc",
    name: "Rest"
  },
  ]

export const PRED_GOOD_AVAILABLE = false

export const TABLE = "atr_correction"

export const OUTPUT_BACKGROUND = 'https://storage.googleapis.com/naman-bucket/dataset/newpersons/'

export const OUTPUT_BACKGROUND_EXT = '.jpg'

export const PREDICTION = `https://storage.cloud.google.com/labelling-tools-data/data-dumps/refined_grapy_7_part_knn/`

export const PREDICTION_EXT = `.png`

export const PREDICTION_FINAL = `https://storage.cloud.google.com/labelling-tools-data/data-dumps/refined_grapy_15_part_final/`
// export const NEW_PREDICTION_FINAL = `https://storage.cloud.google.com/labelling-tools-data/data_dumps/final_densepose_predictions/`

// export const PREDICTION_EXT = `.png`

export const GET_NA_IDS = gql`
query MyQuery($startId: Int!, $endId: Int!) {
  densepose_correction_aggregate(distinct_on: id, where: {is_na: {_eq: "Yes"}, isLabelled: {_eq: "Labelled"}, id: {_gte: $startId, _lte: $endId}}) {
    aggregate {
      count
    }
    nodes {
      id
      reason_for_na
    }
  }
}
`;

export const options = [ 
  {
    value: "",
    label: "None",
  },
  {
    value: "upper_clothes_seg",
    label: "SHIRT / TSHIRT",
    key: "upper_clothes_seg"
  },
  {
    value: "heavy_tops_seg",
    key: "heavy_tops_seg",
    label: "Jacket/hoodies/sweatshirt/sweaters",
  },
  {
    value: "coat_seg",
    key: "coat_seg",
    label: "Coat",
  },
  {
    value: "lower_clothes_seg",
    key: "lower_clothes_seg",
    label: "Pants",
  },
  {
    value: "skirt_seg",
    key: "skirt_seg",
    label: "Skirt",
  },
  {
    value: "dress_seg",
    key: "dress_seg",
    label: "Dress",
  },
  {
    value: "other_accessory_seg",
    key: "other_accessory_seg",
    label: "Other Accessory Seg",
  },
]

export const assignVariables = [
  { 
    name: "upper_clothes_seg",
    label: "SHIRT / TSHIRT",
    color: "rgba(32, 173, 10, 255)",
    options: options,
    variableNameInDb: "upper_clothes_assigned",
  },
  { 
    name: "lower_clothes_seg",
    label: "Pants",
    color: "rgba(117, 112, 2, 255)",
    options: options,
    variableNameInDb: "lower_clothes_assigned",
  },
  { 
    name: "coat_seg",
    label: "Coat",
    color: "rgba(0, 119, 221, 255)",
    options: options,
    variableNameInDb: "coat_assigned",
  },
  { 
    name: "skirt_seg",
    label: "Skirt",
    color: "rgba(255, 0, 0, 255)",
    options: options,
    variableNameInDb: "skirt_assigned",
  },
  { 
    name: "dress_seg",
    label: "Dress",
    color: "rgba(136, 10, 117, 255)",
    options: options,
    variableNameInDb: "dress_assigned",
  }
]


