import { useEffect, useState } from "react";
import * as tf from "@tensorflow/tfjs";
import mergeImages from "merge-images";

export default function useKeyPress(targetKey) {
    // State for keeping track of whether key is pressed
    const [keyPressed, setKeyPressed] = useState(false);
    // If pressed key is our target key then set to true
    function downHandler({ key }) {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    }
    // If released key is our target key then set to false
    const upHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    };
    // Add event listeners
    useEffect(() => {
      window.addEventListener("keydown", downHandler);
      window.addEventListener("keyup", upHandler);
      // Remove event listeners on cleanup
      return () => {
        window.removeEventListener("keydown", downHandler);
        window.removeEventListener("keyup", upHandler);
      };
    }, []); // Empty array ensures that effect is only run on mount and unmount
    return keyPressed;
  }

export const make_assign_variable = (key) => {
  const variableName = key.split("_seg")[0];
  return `${variableName}_assigned`
}

export function resize(img, size, mask = false) {
  let frame_h = size[0];
  let frame_w = size[1];
  let image_h = img.shape.slice([1], [3])[0];
  let image_w = img.shape.slice([1], [3])[1];
  let h_r = frame_h / image_h;
  let w_r = frame_w / image_w;
  let r = Math.min(h_r, w_r);
  let dim = [Math.round(image_h * r), Math.round(image_w * r)];
  let resized;
  if (mask) {
    resized = tf.image.resizeNearestNeighbor(img, [800, 600], false, true);
  } else {
    resized = tf.image.resizeBilinear(img, [dim[0], dim[1]]);
  }
  let z = tf.zeros([800, 600, 1]);
  resized = tf.concat([resized, z], 2);
  resized = tf.util.flatten(resized.arraySync());
  return resized;
}

export function show_single_part(data, colors, removeRest = true) {
  for (let i = 0; i < data.length; i += 4) {
    if (
      data[i] <= parseInt(colors[0]) + 30 &&
      data[i] > parseInt(colors[0]) - 30 &&
      data[i + 1] <= parseInt(colors[1]) + 30 &&
      data[i + 1] > parseInt(colors[1]) - 30 &&
      data[i + 2] <= parseInt(colors[2]) + 30 &&
      data[i + 2] > parseInt(colors[2]) - 30
    ) {
      data[i + 3] = 255;
    } else {
      data[i] = 0;
      data[i + 1] = 0;
      data[i + 2] = 0;
      data[i + 3] = 0;
    }
  }
}

export function make_rest_transparent(data, transparent = 0, color = 255, removeRest = true) {
  if (color === 256) {
    for (let i = 0; i < data.length; i += 4) {
      data[i + 3] = 0;
    }
  } else {
    for (let i = 0; i < data.length; i += 4) {
      if (
        data[i] < 30 &&
        data[i + 1] < 30 &&
        data[i + 2] < 20
      ) {
        data[i + 3] = transparent;
      }else{
        data[i + 3] = 255
      }
      if (removeRest) {
        if (
          data[i] < 230 &&
          data[i] > 190 &&
          data[i + 1] < 230 &&
          data[i + 1] > 190 &&
          data[i + 2] < 230 &&
          data[i + 2] > 190
        ) {
          data[i] = 0;
          data[i + 1] = 0;
          data[i + 2] = 0;
          data[i + 3] = 0;
        }
      }
    }
  }
}

export const makePredictionData = function (image, setLivePrediction, SIZEX, SIZEY) {
  if (image !== "") {
    let newDummyImg = new Image();
    newDummyImg.src = image;
    newDummyImg.crossOrigin = "anonymous";
    newDummyImg.onload = () => {
      URL.revokeObjectURL(newDummyImg.src);
      let canvas = document.createElement("canvas");
      const instaCanvas = canvas;
      let ctx = instaCanvas.getContext("2d");
      canvas.style.width = SIZEX + "px";
      canvas.style.height = SIZEY + "px";
      var scale = 1;
      canvas.width = Math.floor(SIZEX * scale);
      canvas.height = Math.floor(SIZEY * scale);
      ctx.scale(scale, scale);
      ctx.drawImage(
        newDummyImg,
        0,
        0,
        newDummyImg.naturalWidth,
        newDummyImg.naturalHeight,
        0,
        0,
        SIZEX,
        SIZEY
      );

      const imageData = ctx.getImageData(0, 0, 600, 800);
      make_rest_transparent(imageData.data);
      ctx.putImageData(imageData, 0, 0);
      const picURL = canvas.toDataURL();
      setLivePrediction(picURL)
    }
  }
}

export const createAllSegments = (activeIndex, points, ctx, pointsColor, OFFSET_X, OFFSET_Y) => {
  if (activeIndex >= 0) {
    for (let j = points.length - 2; j < points.length - 1; j++) {
      ctx.beginPath();
      if (points[j].length > 0) {
        ctx.moveTo(points[j][0].x, points[j][0].y);
      }
      for (let i = 1; i < points[j].length; i++) {
        ctx.lineTo(points[j][i].x - OFFSET_X, points[j][i].y - OFFSET_Y);
      }
      ctx.lineWidth = 2;
      ctx.fillStyle = pointsColor[j].colorPreview;
      ctx.strokeStyle = pointsColor[j].colorPreview;
      ctx.fill();
      ctx.stroke();
      ctx.save();
    }
  }
  ctx.restore();
}

function make_base(ctx, src, sizeX, sizeY) {
  let base_image = new Image();
  base_image.src = src;
  base_image.onload = function () {
    URL.revokeObjectURL(base_image.src);
    ctx.drawImage(base_image, 0, 0, sizeX, sizeY);
  };
}

export function createImage(ctx, list, sizeX, sizeY) {
  let newlist = [];
  for (let i = 0; i < list.length; i += 1) {
    if (list[i] !== null && list[i] !== undefined) {
      newlist.push({
        src: list[i],
        opacity: 0.5,
      });
    }
  }
  mergeImages(newlist).then(async (b64) => {
    await make_base(ctx, b64, sizeX, sizeY);
  });
}

export const use_assign = (imageData,assign, allParts, assignDone, setAssignDone)=> {

  if(Object.keys(assign).length > 0){
    let colorsSet = new Set()
    let variableColorMap = {}
    let allPartsObj = convertArrayToObject(allParts, "variable")
    Object.keys(assign).forEach(key => {
      if(assign[key] && allPartsObj[key]){
        variableColorMap[`${allPartsObj[key]['color']}`] = allPartsObj[assign[key]]['color'];
      }
    })

    console.log(variableColorMap, assign)

    for (let i = 0; i < imageData.data.length; i += 4) {
      let color = "rgba(" + imageData.data[i].toString() + ", " + imageData.data[i+1].toString() + ", "  + imageData.data[i+2].toString() + ", " + (255).toString() + ")"
      if(color in variableColorMap){
          imageData.data[i] = parseInt(variableColorMap[color].split("rgba(")[1].split(",")[0]);
          imageData.data[i+1] = parseInt(variableColorMap[color].split("rgba(")[1].split(",")[1]);
          imageData.data[i+2] = parseInt(variableColorMap[color].split("rgba(")[1].split(",")[2]);
          imageData.data[i+3] = 255;
      }
      colorsSet.add(color)
    }
    setAssignDone(!assignDone)
    return true
  }
  
}


export function convertArrayToObject(array, keyInEachObject){
  let objectFromArray = {};
  for (let i = 0; i < array.length; i +=1 ){
    objectFromArray[array[i][keyInEachObject]] = array[i];
  }
  return objectFromArray
}

export function convertStringToColor(color){
  let colors = color.split("rgba(")[1]
  console.log(colors)
  // colors  = 
}

export function intersect(x1, y1, x2, y2, x3, y3, x4, y4) {
  
  // Check if none of the lines are of length 0
  if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
    return false
  }

  let denominator = ((y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1))

  // Lines are parallel
  if (denominator === 0) {
    return false
  }

  let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator
  let ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator

  // is the intersection along the segments
  if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
    return false
  }

  // Return a object with the x and y coordinates of the intersection
  let x = x1 + ua * (x2 - x1)
  let y = y1 + ua * (y2 - y1)

  return {x: Math.round(x), y: Math.round(y)}
}

export  function getMousePos(cont, evt, lastPoint) {
  const rect = cont.current.getBoundingClientRect();
  let currentX =  evt.clientX - rect.x;
  let currentY = evt.clientY - rect.y;
  
  if(evt.target.id===""){
    currentX = currentX - 21
    currentY = currentY - 21
    if (currentX < 0 || currentX > 600 || currentY < 0 || currentY > 800) {
      if(lastPoint.x == 600 || lastPoint.x == 0){
        return {x: lastPoint.x, y: currentY > 800 ? 800 : currentY < 0 ? 0: currentY };
      }
      if(lastPoint.y == 800 || lastPoint.y == 0){
        return {x: currentX > 600 ? 600 : currentX < 0 ? 0: currentX, y: lastPoint.y}
      }
    let intersectionPoints = []
    // console.log(currentX, currentY, lastPoint)
    // console.log(intersect(currentX, currentY, lastPoint.x, lastPoint.y, 600, 0, 600, 800))
    // console.log()
    if(intersect(currentX, currentY, lastPoint.x, lastPoint.y, 0, 0, 600, 0)){
      intersectionPoints.push(intersect(currentX, currentY, lastPoint.x, lastPoint.y, 0, 0, 600, 0));
    }
    if(intersect(currentX, currentY, lastPoint.x, lastPoint.y, 0, 800, 600, 800)){
      intersectionPoints.push(intersect(currentX, currentY, lastPoint.x, lastPoint.y, 0, 800, 600, 800));
    }
    if(intersect(currentX, currentY, lastPoint.x, lastPoint.y, 600, 0, 600, 800)){
      intersectionPoints.push(intersect(currentX, currentY, lastPoint.x, lastPoint.y, 600, 0, 600, 800));
    }
    if(intersect(currentX, currentY, lastPoint.x, lastPoint.y, 0, 0, 0, 800)){
      intersectionPoints.push(intersect(currentX, currentY, lastPoint.x, lastPoint.y, 0, 0, 0, 800));
    }
  
    for(let i = 0; i < intersectionPoints.length; i++){
      let intersectionPointX = intersectionPoints[0].x
      let intersectionPointY = intersectionPoints[0].y
      let minX = Math.min(currentX, lastPoint.x)
      let minY = Math.min(currentY, lastPoint.y)
      let maxX = Math.max(currentX, lastPoint.x)
      let maxY = Math.max(currentY, lastPoint.y)
      if(intersectionPointX >= minX && intersectionPointX <= maxX && intersectionPointY >= minY && intersectionPointY <= maxY){
        return {
          x: intersectionPointX,
          y: intersectionPointY
        }
      }
  
    }
    }
  }
    return {
      x: evt.clientX - rect.x,
      y: evt.clientY - rect.y,
    };
}